$dimensions: 30px;
$scale: 0.75;

.recommendations-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 1;
  padding: 1rem;
  overflow: scroll;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;

  @media screen and (max-width: 768px) {
    padding: 0.25rem;
  }
}

.recommendation-box {
  background: white;

  border: 1px solid $grey-light;
  border-radius: 5px;
  box-shadow: 0px 5px 6px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-right: 1rem;
  flex: 1 0 25%;

  @media screen and (max-width: 768px) {
    flex-basis: calc(100vw - 0.5rem);
  }
}

.recommendation {
  background-color: white;

  &__marker {
    position: absolute;
    left: -($dimensions / 2);
    top: -($dimensions / 2);
    background: white;
    border-radius: 50%;
    border: 2px darken($info, 5) solid;
    width: $dimensions;
    height: $dimensions;
    transform: scale($scale);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all ease 400ms;
  }

  &--hovered {
    .recommendation__marker {
      transform: scale(1);
      border-width: 4px;
    }
  }

  &__hover-box {
    padding: 15px;
    width: 150px;
    background: white;
    z-index: 100;
    position: absolute;
    top: ($dimensions / 2) + 5px;
    left: -75px;
  }
}
