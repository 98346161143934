$search-padding: 0.25rem;

.search {
  .title {
    font-family: 'Courier New';
  }

  &--is-hidden {
    transform: translateY(-75%);
  }

  @media screen and (min-width: 768px) {
    width: 33.3%;
    padding: #{$search-padding};
  }

  @media screen and (min-width: 1024px) {
    width: 25%;
  }

  &__filter {
    margin: 1rem 0;
  }

  > .box {
    max-height: calc(100vh - #{$search-padding} * 2);
    overflow: scroll;
  }
}

.search-hide {
  opacity: 100;
  transition: opacity ease-in-out 400ms;
  text-align: center;
  margin: 1rem 0;
  display: block;
  width: 100%;

  &--is-hidden {
    opacity: 0;
  }
}

.location-list {
  list-style-type: none;
  margin: 0;
}

.location-list-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;

  p {
    margin-bottom: 0;
    margin-left: 0.75rem;
    flex-grow: 2;
  }

  button {
    margin: 0 0.5rem;
  }

  .delete {
    cursor: pointer;
  }
}

.suggestion {
  padding: 0.75rem 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  transition: background-color 200ms ease;

  &:hover,
  &:focus {
    background-color: $grey-lighter;
  }

  .icon {
    min-width: 2rem;
    min-height: 2rem;
    margin-right: 0.5rem;
  }
}

.saved-searches {
  position: absolute;
  opacity: 1;
  transition: all ease-in-out 500ms;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  &--is-hidden {
    opacity: 0;
    top: -100%;
  }
}
